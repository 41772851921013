import React from "react";

import Spinner from "react-bootstrap/Spinner";

import "./Loading.css";

export default function Loading() {
	return (
		<div className="loading-container">
			<Spinner animation="border" variant="primary" role="status">
				<span className="visually-hidden">A Carregar...</span>
			</Spinner>
		</div>
	);
}
