import React from "react";
import { Link, useLocation } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";

import Icon from "../common/Icon";
import Footer from "./Footer";

import "./ProductLayout.css";
import logo from "../images/gesa_logo_full.svg";

export default function ProductLayout(props) {
	const navLinks = [
		["/home", "Início"],
		["/about", "Sobre Nós"],
		["/services", "Serviços"],
		["/quote", "Pedir Orçamento"],
		["/contact", "Contactos"],
	];
	const location = useLocation();
	return (
		<div className="product_layout full">
			<header className="sticky-top">
				<Navbar
					id="product_navbar"
					bg="light"
					variant="light"
					expand="md"
				>
					<Navbar.Brand as={Link} to="/">
						<Image
							src={logo}
							alt="Gesaímo"
							id="product_navbar_logo"
						/>
					</Navbar.Brand>
					<Navbar.Toggle
						aria-controls="product_navbar_nav"
						label="Navegação"
					/>
					<Navbar.Collapse id="product_navbar_nav">
						<Nav
							activeKey={location.pathname}
							className="me-auto justify-content-around w-100 fw-bold"
							role="navigation"
						>
							{navLinks.map(nl => (
								<Nav.Link
									as={Link}
									key={nl[0]}
									eventKey={nl[0]}
									to={nl[0]}
								>
									{nl[1]}
								</Nav.Link>
							))}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<div id="user_context_bar" className="bg-secondary text-white">
					<strong>myGesa:</strong>
					&ensp;
					<Link
						to="/auth/login"
						className="user_context_link text-reset text-decoration-none"
					>
						<Icon id="box-arrow-in-right" spaceAfter />
						<span>Entrar</span>
					</Link>
					&ensp;|&ensp;
					<Link
						to="/auth/register"
						className="user_context_link text-reset text-decoration-none"
					>
						<Icon id="person-plus" spaceAfter />
						<span>Registar</span>
					</Link>
				</div>
			</header>
			<div className="content" role="main">
				{props.children}
			</div>
			{/* TODO: cookie banner */}
			<Footer />
		</div>
	);
}
