import React from "react";

import "./Icon.css";
import sheet from "../../node_modules/bootstrap-icons/bootstrap-icons.svg";

export default function Icon(props) {
	const humanReadableId = props.id
		.split("-")
		.map(s => s[0].toUpperCase() + s.substr(1))
		.join(" ");
	return (
		<>
			<svg
				role="img"
				alt={props.alt}
				className="icon bi"
				fill={props.color || "currentColor"}
			>
				{props.alt && <title>{props.alt}</title>}
				<use href={sheet + "#" + props.id} />
			</svg>
			<span className="visually-hidden">
				[{props.alt || humanReadableId}]
			</span>
			{props.spaceAfter && "\u2004"}
		</>
	);
}
