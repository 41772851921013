import React, { useEffect } from "react";

import FunctionalLayout from "../layouts/FunctionalLayout";
import ProductLayout from "../layouts/ProductLayout";
import LonePanelLayout from "../layouts/LonePanelLayout";

export default function Page(props) {
	useEffect(() => {
		document.title = props.docTitle
			? "Gesaímo :: " + props.docTitle
			: "Gesaímo";
	}, [props.docTitle]);

	let Layout = FunctionalLayout;
	if (typeof props.layout == "string") {
		if (props.layout.toUpperCase() === "PRODUCT") {
			Layout = ProductLayout;
		} else if (props.layout.toUpperCase() === "LONE_PANEL") {
			Layout = LonePanelLayout;
		}
	}
	return <Layout>{props.children}</Layout>;
}
