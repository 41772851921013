import React, { Suspense } from "react";
import {
	Navigate,
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";

import Page from "./common/Page";
import Loading from "./common/Loading";
import ReCAPTCHASiteKeyContext from "./contexts/ReCAPTCHASiteKeyContext";

const reCAPTCHASiteKey = "6LcNQlUaAAAAAM8T1DttF7nw2YC3xocFVCaQFHGS";

function buildPage({ title, layout, element: Element }) {
	return (
		<Page docTitle={title} layout={layout}>
			<ReCAPTCHASiteKeyContext.Provider value={reCAPTCHASiteKey}>
				<Element />
			</ReCAPTCHASiteKeyContext.Provider>
		</Page>
	);
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to="/home" />,
	},
	{
		path: "/home",
		element: buildPage({
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/Home")),
		}),
	},
	{
		path: "/about",
		element: buildPage({
			title: "Sobre Nós",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/About")),
		}),
	},
	{
		path: "/services",
		element: buildPage({
			title: "Serviços",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/Services")),
		}),
	},
	{
		path: "/quote",
		element: buildPage({
			title: "Pedir Orçamento",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/Quote")),
		}),
	},
	{
		path: "/contact",
		element: buildPage({
			title: "Contactos",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/Contact")),
		}),
	},

	{
		path: "/legal/terms",
		element: buildPage({
			title: "Termos de Serviço",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/TermsOfService")),
		}),
	},
	{
		path: "/legal/privacy",
		element: buildPage({
			title: "Política de Privacidade",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/PrivacyPolicy")),
		}),
	},
	{
		path: "/legal/ral",
		element: buildPage({
			title: "Resolução Alternativa de Litígios",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/RAL")),
		}),
	},
	{
		path: "/auth/*",
		element: buildPage({
			title: "Indisponível",
			layout: "PRODUCT",
			element: React.lazy(() => import("./product/Unavailable")),
		}),
	},
	{
		path: "*",
		element: buildPage({
			title: "Não Encontrado",
			element: React.lazy(() => import("./common/NotFound")),
		}),
	},
]);

export default function App() {
	return (
		<Suspense fallback={<Loading />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}
