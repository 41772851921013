import React from "react";
import { createRoot } from "react-dom/client";

import ErrorBoundary from "./common/ErrorBoundary";
import App from "./App";

import "./custom_bootstrap.scss";
import "./index.css";

const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</React.StrictMode>
);
