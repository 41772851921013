import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ldr from "../images/LivroDeReclamacoesTransparent.png";
import "./Footer.css";

export default function Footer(props) {
	const info = [
		{
			heading: "Contactos",
			component: "address",
			rows: [
				[
					"Av. 5 de Outubro 115, 2º F",
					"https://goo.gl/maps/rgmmKbDk9EKpkT7G9",
				],
				["1050-052 LISBOA", "https://goo.gl/maps/rgmmKbDk9EKpkT7G9"],
				["info@gesaimo.pt", "mailto:info@gesaimo.pt"],
				["+351 217 800 635", "tel:+351217800635"],
			],
		},
		{
			heading: "Avisos Legais",
			rows: [
				["Termos de Serviço", "/legal/terms"],
				["Política de Privacidade", "/legal/privacy"],
				["Resolução Alternativa de Litígios", "/legal/ral"],
				[
					<img
						id="img_livro_reclamacoes"
						alt="Livro de Reclamações"
						src={ldr}
					/>,
					"https://www.livroreclamacoes.pt/inicio",
				],
			],
		},
	];
	return (
		<footer className="p-4 w-100 text-center small bg-dark text-white">
			<Container fluid>
				<Row>
					{info.map(col => (
						<Col as={col.component} key={col.heading}>
							<h3>{col.heading}</h3>
							<ul className="list-unstyled">
								{col.rows.map(c => (
									<li key={c[0]}>
										<a
											href={c[1]}
											target="_blank"
											rel="noreferrer"
											className="text-reset"
										>
											{c[0]}
										</a>
									</li>
								))}
							</ul>
						</Col>
					))}
				</Row>
				{/* TODO: flags */}
				<Row>
					<Col>
						<p>
							&copy; Gesaímo Lda. &mdash; Todos os direitos
							reservados.
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}
